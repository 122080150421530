<template>
  <section>
    <v-container fluid class="grey lighten-4 pa-10">
      <v-row>
        <v-col cols="12" md="4" class="d-flex justify-center">
          <app-person
            name="Dr. Christophe WILCKE"
            functions="Président de l’URPS Pharmaciens Grand Est"
            :image="require('../../../assets/img/avatar/avatar-christophe.jpg')"
          >
          </app-person>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center">
          <app-quote>
            <p>
              <strong>
                Chère consœur, cher confrère,
              </strong>
            </p>
            <p>
              Dans son rapport "Assurer le premier accès aux soins - Organiser
              les soins non programmés dans les territoires", Thomas Mesnier
              affirme qu’il est impératif, de façon générale, mais tout
              particulièrement dans le cas des soins non programmés, de dégager
              du temps médical utile, en encourageant beaucoup plus résolument
              qu’aujourd’hui le partage de tâches entre médecins et autres
              professions de santé (infirmiers, kinésithérapeutes, pharmaciens).
            </p>
            <p>
              <strong>
                Le pharmacien, en collaboration avec les autres professionnels
                de santé, est un acteur essentiel de la prise en charge des
                demandes de soins non programmées.
              </strong>
            </p>
            <p>
              Ces demandes en officines ne sont actuellement pas ou peu évaluées
              autant qualitativement que quantitativement. C’est pourquoi,
              l’URPS Pharmaciens Grand Est s’engage dans une ENQUETE SUR LES
              DEMANDES DE SOINS NON PROGRAMMEES EN OFFICINE dans la région Grand
              Est, avec le soutien méthodologique et financier de l’ARS et Tous
              Pour La Santé. Nous avons besoin de vous pour la réussite de cette
              étude. Cette enquête à laquelle chaque officine du Grand Est est
              invitée à participer, sera déployée sur la région à partir du 19
              novembre 2020 pour une durée de 6 mois.
            </p>
            <p>
              Confraternellement,
            </p>
            <p>
              <strong>
                Dr. Christophe WILCKE
              </strong>
            </p>
            <p>
              Président de l’URPS Pharmaciens Grand Est
            </p>
          </app-quote>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'CitationSection',

  components: {
    'app-quote': () => import('../../../core/Citation'),
    'app-person': () => import('../../../core/Person')
  }
}
</script>
